

.home-wrapper {
    background-color: #282c34;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}


.contents-wrapper {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.server-item {
    background-color: white;
    display: flex;
    padding: 10px;
    flex-direction: row;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 18px;
}


.country-flag {
    width: 100%;
    height: 100px;
    background-size: cover;
    background-position: center  !important;

}


.add-server {
    height: 97%;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.add-server:hover {
    opacity: .8;
    cursor: pointer;
}


.servers-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 15px;

}



@media (min-width: 360px) {
    .servers-list {
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 5px;
    }
}
@media (min-width: 768px) {
    .servers-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 15px;
    }
}
@media (min-width: 1024px) {
    .servers-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr  ;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 15px;
    }
}
@media (min-width: 1400px) {
    .servers-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 15px;
    }
}