



.users-wrapper {
    background-color: #282c34;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}
.users-manager {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
    padding-bottom: 7%;
}

.user-avatar {
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 5%;
}
/* .user-avatar::after {
    content: "";
    height: 2em;
    width: 2em;
    top: 30%;
    left: 55%;
    position: absolute;
    background-color: lightseagreen;
    border-radius: 50%;
} */

.user-statistics {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}
.search-manager {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
    padding-top: 2%;
}

.search-wrapper {
    width: 80%;
    margin: 0 auto;
}




@media (min-width: 360px) {
    .users-manager {
        grid-template-columns: 1fr;
        width: 80%;
        grid-gap: 5px;
    }
    .search-manager {
        grid-template-columns: 1fr;
        width: 100%;
        grid-gap: 5px;
    }
    .user-statistics {
        flex-direction: row;
    }
}
@media (min-width: 768px) {
    .users-manager {
        grid-template-columns: 1fr 1fr 1fr;
        width: 80%;
        grid-gap: 5px;
    }
    .search-manager {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        grid-gap: 5px;
    }
    .user-statistics {
        flex-direction: row;
    }
}
@media (min-width: 1024px) {
    .users-manager {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 80%;
        grid-gap: 15px;
    }
    .search-manager {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        grid-gap: 5px;
    }
    .user-statistics {
        flex-direction: row;
    }
}
@media (min-width: 1400px) {
    .users-manager {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: 80%;
        grid-gap: 15px;
    }
    .search-manager {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: 100%;
        grid-gap: 5px;
    }
    .user-statistics {
        flex-direction: row;
    }
}