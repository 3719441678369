

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-credentials {
    background-color: white;
    border-radius: 21px;
    width: 50%;
    padding: 10px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 80%;
}




@media (min-width: 360px) {
    .login-credentials {
        width: 90%;
    }
}
@media (min-width: 768px) {
    .login-credentials {
        width: 90%;
    }
}
@media (min-width: 1024px) {
    .login-credentials {
        width: 70%;
    }
    
}
@media (min-width: 1400px) {
    .login-credentials {
        width: 50%;
    }
}