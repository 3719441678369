


.title {
    color: white;
    font-size: 22pt;
    font-weight: bold;
}

.title-small {
    color: white;
    font-size: 18pt;
    font-weight: bold;
}


.title-small-dark {
    color: black;
    font-size: 18pt;
    font-weight: bold;
}

.title-smaller {
    color: white;
    font-size: 14pt;
    font-weight: bold;
}



.desc {
    color: black;
    font-size: 9pt;
}

.desc-margin {
    color: black;
    font-size: 9pt;
    line-height: 0;
}


.desc-opacity {
    color: black;
    font-size: 9pt;
    opacity: .5;
}