.App {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #282c34;
}


body, html {
  margin: 0;
  padding: 0;
  background-color: #282c34;
}