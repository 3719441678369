

.user-details-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.user-status {
    height: 80px;
    width: 100%;
    border-radius: 21px;
    box-shadow: 0 3px 60px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.user-status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.user-info-wrapper {
    padding-top: 10px;
}