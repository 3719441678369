

.ant-drawer-content-wrapper {
    width: 35% !important;
}
.flag-item {
    height: 8em;
    width: 8em;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    margin-bottom: 5%;
}

@media (min-width: 360px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}
@media (min-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}
@media (min-width: 1024px) {
    .ant-drawer-content-wrapper {
        width: 35% !important;
    }
}
@media (min-width: 1400px) {
    .ant-drawer-content-wrapper {
        width: 35% !important;
    }
}